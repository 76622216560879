import React from 'react';
import { graphql } from 'gatsby';

import { Grid, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ThankYouHero } from '../components/ThankYous/ThankYouHero';
import { SEO } from '../components/SEO';
import FacebookIcon from '../images/facebook-icon-circle.png'

const useStyles = makeStyles((theme) => ({
	img: {
		width: '100%',
	},
	text: {
		color: theme.white,
		fontWeight: 600,
	},
	facebookImg: {
		marginBottom: '1.5rem',
		maxWidth: '60px',
		[theme.breakpoints.up('md')]: {
			marginRight: '1.5rem',
			marginBottom: '0',
		},
	},
	facebook: {
		fontWeight: '700',
		fontSize: '1.5rem',
		lineHeight: '1.2',
		color: theme.mediumGray,
		marginBottom: '1.5rem',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			marginRight: '1.5rem',
			marginBottom: '0',
		},
	},
	button: {
		fontWeight: '500',
		background: theme.workwaveBlue,
		color: theme.white,
		lineHeight: '1.5',
		'&:hover': {
			transform: 'translateY(-1px)',
			opacity: '.85',
			color: '#fff',
			boxShadow: '0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%)',
			backgroundColor: '#19305a',
			transition: 'all .15s ease',
			whiteSpace: 'nowrap',
		},
	},
}));

const ThankYouPage = ({ data: { thankyou } }) => {
	const classes = useStyles();
	const lg = useMediaQuery('(max-width: 1280px)');
	const med = useMediaQuery('(max-width: 1024px)');

	return (
		<>
			<SEO title={thankyou.metaTitle} description={thankyou.metaDescription} />
			<ThankYouHero
				thankyou={thankyou}
			/>
			<Container style={{ marginTop: '2rem', marginBottom: '3rem' }} fixed>
				<Grid container direction='row' justify='center' alignItems='center'>
					<Grid
						container
						item
						xs={12}
						direction='row'
						justify='center'
						alignItems='center'>
						<img
							src={FacebookIcon}
							alt='Facebook'
							className={classes.facebookImg}
						/>
						<Typography
							className={classes.facebook}
							variant='h4'
							style={{ fontSize: med ? '1.3rem' : '1.5rem' }}>
							Join our Facebook group to learn more about our community.
						</Typography>
						<a
							href='https://www.facebook.com/groups/workwaveresourcegroup'
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'none' }}>
							<Button
								variant='contained'
								className={classes.button}
								style={{
									padding: !lg ? '.5rem 1rem' : '.5rem 1rem',
									fontSize: !lg ? '1.25rem' : '16',
								}}>
								JOIN NOW
							</Button>
						</a>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export const query = graphql`
	query ($slug: String!) {
    thankyou: sanityThankyous(slug: {current: {eq: $slug}}) {
      _id
      heroImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      heroSubTitle
      heroTitle
      metaDescription
      metaTitle
      title
      slug {
        current
      }
    }
	}
`;

export default ThankYouPage;
