import React from 'react';
import { BgImage } from 'gbimage-bridge';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid, Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	img: {
		width: '100%',
	},
	textH1: {
		color: theme.workwaveBlue,
		fontWeight: 700,
		lineHeight: '.95',
	},
	text: {
		fontSize: '1.2rem',
		lineHeight: '1.2',
		color: theme.mediumGray,
	},
	textCenter: {
		textAlign: 'center',
	},
  button: {
		fontWeight: '500',
		background: theme.workwaveBlue,
		color: theme.white,
		lineHeight: '1.5',
		'&:hover': {
			transform: 'translateY(-1px)',
			opacity: '.85',
			color: '#fff',
			boxShadow:
				'0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%)',
			backgroundColor: '#19305a',
			transition: 'all .15s ease',
			whiteSpace: 'nowrap',
		},
	},
}));

export const ThankYouHero = ({ thankyou }) => {
	const classes = useStyles();
	const lg = useMediaQuery('(max-width: 1280px)');
	const med = useMediaQuery('(max-width: 768px)');
	const sm = useMediaQuery('(max-width: 575px)');

  const handleHeroClick = (e) => {
    e.preventDefault();
		navigate(`/`);
	};

	return (
		<BgImage image={thankyou.heroImage?.asset?.gatsbyImageData} className={classes.img}>
			<Container fixed>
				<Grid
					container
					direction='row'
					justify='center'
					alignItems='center'
					style={{
						minHeight: lg ? '400px' : '500px',
						padding: lg ? '1rem' : null,
						
					}}>
					<Grid item lg={10} xs={12} className={classes.textCenter}>
						<Typography
							variant='h1'
							className={classes.textH1}
							style={{ fontSize: sm ? '1.5rem' : med ? '2.2rem' : '2.8rem' }}
							gutterBottom>
							{thankyou.heroTitle}
						</Typography>
						<Typography
							variant='body1'
							style={{ fontWeight: 400, paddingBottom: '1.5rem' }}
							className={classes.text}>
							{thankyou.heroSubTitle}
						</Typography>
						<Button
							variant='contained'
							className={classes.button}
              onClick={e => handleHeroClick(e) }
							style={{ padding: !lg ? '.5rem 2.5rem' : null, fontSize: !lg ? '1.25rem' : '16' }}>
							GO BACK
						</Button>
					</Grid>
				</Grid>
			</Container>
		</BgImage>
	);
};
